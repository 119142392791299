import React, { useState } from 'react'
import styles from './Register.module.scss'
import { CButton, CForm, CFormGroup, CFormText, CInput, CLabel } from '@coreui/react'
import { useForm } from 'react-hook-form'
import CustomInput from './components/CustomInput'
import { useMutation } from 'react-query'
import PanelUserService from '../../services/PanelUserService'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import Select from '../../components/Select'
import { CheckCircle } from "react-feather";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from './components/Header'
import cs from 'classnames'
import { TheFooter } from '../../containers'


const pickupPointSchema = yup.object().shape({
    shop_data: yup.object().shape({
        name: yup.string().required("Pole wymagane").min(2, "Nazwa musi być dłuższa niż 2 znaki"),
        symbol: yup.string().required("Pole wymagane").matches(/^([ŻÓŁĆĘŃĄŚŹA-Z1-9]){4}$/, "Nazwa skrócona to 4 duże znaki alfanumeryczne"),
        street: yup.string().required("Pole wymagane").min(2, "Nazwa ulicy musi być dłuższa niż 2 znaki"),
        city: yup.string().required("Pole wymagane"),
        postal_code: yup.string().required("Pole wymagane").min(6, 'Kod pocztowy powinien mieć format XX-XXX').max(6, 'Kod pocztowy powinien mieć format XX-XXX').matches(/\d{2}-\d{3}/, "Podano nieprawidłowy kod pocztowy"),
        nip: yup.string().required("Pole wymagane").matches(/\d{10}/, 'NIP musi składać się z 10 cyfr').min(10, "NIP musi składać się z 10 cyfr").max(10, "NIP musi składać się z 10 cyfr"),
        regon: yup.string().required("Pole wymagane").matches(/\d{9}/, 'Numer REGON musi składać się z 9 cyfr').min(9, "Numer REGON musi składać się z 9 cyfr").max(9, "Numer REGON musi składać się z 9 cyfr"),
        email: yup.string().required("Pole wymagane").email("Nieprawidłowy email"),
        phone: yup.string().required("Pole wymagane").matches(/\d/, 'Numer telefonu musi składać się z cyfr'),
    }),
    personal_data: yup.object().shape({
        name: yup.string().required("Pole wymagane").matches(/^[a-zśżółćęąźńŻÓŁĆĘŃĄŚŹA ,.'-]+$/i, 'Nieprawidłowy format imienia').min(2, "Imię musi być dłuższa niż 2 znaki"),
        surname: yup.string().required("Pole wymagane").matches(/^[a-zśżółćęąźńŻÓŁĆĘŃĄŚŹA ,.'-]+$/i, 'Nieprawidłowy format nazwiska').min(2, "Nazwisko musi być dłuższa niż 2 znaki"),
        phone: yup.string().required("Pole wymagane").matches(/\d/, 'Numer telefonu musi składać się z cyfr'),
        email: yup.string().email("Nieprawidłowy email").required("Pole wymagane"),
    }),
    consents: yup.object().shape({
        consent1: yup.boolean().isTrue("Zaznaczenie tej zgody jest wymagane").required("Zaznaczenie tej zgody jest wymagane"),
    }),
});

// const fakeData = {
//    shop_data: {
//        name: "Nowy Partner",
//        street: "Krzywa 1",
//        city: "Gdańsk",
//        postal_code: "11-200",
//        nip: "1234567890",
//        krs: "1234567890",
//        regon: "1234567890",
//        email: 'm.lewandowski@muchmore.pl',
//        phone: '1234567890',
//    },
//    personal_data: {
//        name: "Kamil",
//        surname: "Szybki",
//        phone: "1234567890",
//        email: "m.lewandowski@muchmore.pl"
//    }
// }

const Register = () => {
    const [step, setStep] = useState(0)
    const { register, handleSubmit, formState: { errors }, setError } = useForm(
        {
          // defaultValues: fakeData,
          resolver: yupResolver(pickupPointSchema)
        },
    )


    const [registerPartner, { isLoading: isLoadingRegisterPartner }] = useMutation(
        PanelUserService.createPartner(),
        {
            onSuccess: () => {
                toast.success('Partner został dodany')
                setStep(step => step + 1)
            },
            onError: (data) => {
                if (data?.response?.data) {
                  const personalErrors = data.response.data.personal_data?.errors || [];
                  const shopErrors = data.response.data.shop_data?.errors || [];
                  for (const key in personalErrors) {
                    let errorKey = personalErrors[key]['invalid_property']
                    if (errorKey === 'c2') {
                      errorKey = 'name'
                    }
                    if (errorKey === 'c3') {
                      errorKey = 'surname'
                    }
                    if (errorKey === 'c17') {
                      errorKey = 'phone'
                    }
                    if (errorKey === 'c16') {
                      errorKey = 'email'
                    }

                    const errorValue = personalErrors[key]['message']
                    setError(`personal_data[${errorKey}]`, {message: errorValue} )
                  }
                  for (const key in shopErrors) {
                    const errorKey = shopErrors[key]['invalid_property']
                    const errorValue = shopErrors[key]['message']
                    setError(`shop_data[${errorKey}]`, {message: errorValue} )
                  }

                } else {
                    toast.error('Ups... Coś poszło nie tak.')
                }
            }
        }
    )



    const isLoading = isLoadingRegisterPartner



    const handlePartner = () => {
        setStep(step => step + 1)
    }

    const partnerOptions = [
        { value: 'COMMERCIAL', label: 'Handlowy' },
        { value: '', label: 'Usługowy', isDisabled: true },
    ]

    const [partnerType, setPartnerType] = useState(partnerOptions[0])
    const [name, setName] = useState();
    const restrictCharacters = (e) => {
      if (!e.key.match(/[a-zA-Z0-9]/)) {
        e.preventDefault();
      }
    }


    const onSubmit = async (data) => {
        const newData = { ...data }
        newData.shop_data.type = partnerType.value
        await registerPartner(data)
    }

    return (
        <>
            {
                isLoading && <Loader show={true} />
            }
            <div className={styles.container}>
                <Header />
                <CForm onSubmit={handleSubmit(onSubmit)} className={styles.card}>
                    {
                        step === 0 && (
                            <div className='w-100'>
                                <div className={styles.wrapper}>
                                    <h3 className={styles.h3}>
                                        Typ partnera
                                    </h3>
                                    <CFormGroup>
                                        <Select
                                            className=''
                                            defaultValue={partnerType}
                                            onChange={setPartnerType}
                                            options={partnerOptions}
                                        />
                                    </CFormGroup>
                                    <CButton className='w-100' onClick={handlePartner} type="button" size="xl" color="primary" shape="square">
                                        Wybierz
                                    </CButton>
                                </div>
                            </div >

                        )
                    }
                    {/* STEP 1 */}
                    {
                        step === 1 && (
                            <>
                                <div className={cs(styles.step1, 'flex-column', 'flex-sm-row')}>
                                    <div className='w-100'>
                                        <h3 className={styles.h3}>
                                            Dane Partnera
                                        </h3>
                                        <CFormGroup>
                                            <CLabel htmlFor="name">Nazwa</CLabel>
                                            <CInput
                                                id='name'
                                                name="shop_data.name"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.name?.message}
                                            </CFormText>
                                        </CFormGroup>
                                      <CFormGroup>
                                        <CLabel htmlFor="symbol">Nazwa skrócona</CLabel>
                                        <CInput
                                          id='symbol'
                                          name="shop_data.symbol"
                                          innerRef={register({ required: true })}
                                          onChange={(e) => setName(e.target.value.toUpperCase())}
                                          onKeyPress={(e) => restrictCharacters(e)}
                                          onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                          maxLength={4}
                                        />
                                        <div className="small mb-1">
                                          Nazwa skrócona będzie wyróżnikiem Partnera w systemie rezerwacji i zamówień Vibe. Wyróżnik będzie wykorzystany m.in. w numeracji zamówień, np. ZAM-{name}-0001. Wyróżnik składa się z 4 liter.
                                        </div>
                                        <CFormText className="text-right" color="danger">
                                          {errors.shop_data?.symbol?.message}
                                        </CFormText>
                                      </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="email">Email</CLabel>
                                            <CInput
                                                id='email'
                                                name="shop_data.email"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.email?.message}
                                            </CFormText>

                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone">Telefon</CLabel>
                                            <CInput
                                                id='phone'
                                                name="shop_data.phone"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.phone?.message}
                                            </CFormText>

                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="nip">NIP</CLabel>
                                            <CInput
                                                id='nip'
                                                name="shop_data.nip"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.nip?.message}
                                            </CFormText>

                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="regon">Regon</CLabel>
                                            <CInput
                                                id='regon'
                                                name="shop_data.regon"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.regon?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="krs">KRS</CLabel>
                                            <CInput
                                                id='krs'
                                                name="shop_data.krs"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.krs?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="street">Ulica</CLabel>
                                            <CInput
                                                id='street'
                                                name="shop_data.street"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.street?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="city">Miasto</CLabel>
                                            <CInput
                                                id='city'
                                                name="shop_data.city"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.city?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="postal_code">Kod pocztowy</CLabel>
                                            <CInput
                                                id='postal_code'
                                                name="shop_data.postal_code"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.shop_data?.postal_code?.message}
                                            </CFormText>
                                        </CFormGroup>
                                    </div>
                                    <div className='w-100'>
                                        <h3 className={styles.h3}>
                                            Dane Osobowe
                                        </h3>
                                        <CFormGroup>
                                            <CLabel htmlFor="name">Imię</CLabel>
                                            <CInput
                                                id='name'
                                                name="personal_data.name"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.personal_data?.name?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="surname">Nazwisko</CLabel>
                                            <CInput
                                                id='surname'
                                                name="personal_data.surname"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.personal_data?.surname?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone">Telefon</CLabel>
                                            <CInput
                                                id='phone'
                                                name="personal_data.phone"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.personal_data?.phone?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="email">Email</CLabel>
                                            <CInput
                                                id='email'
                                                name="personal_data.email"
                                                innerRef={register({ required: true })}
                                            />
                                            <CFormText className="text-right" color="danger">
                                                {errors.personal_data?.email?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CustomInput id={"consent1"} innerRef={register({ required: true })} name="consents.consent1" required={true} desc={'Wyrażam zgodę na przetwarzanie przez Univibe Sp. z o.o. (ul. Sarmacka 1/19, 02-972 Warszawa), moich danych osobowych w postaci moich danych osobowych, adresu email, numeru telefonu w celu przesyłania mi za pomocą środków komunikacji elektronicznej oraz telekomunikacji, informacji handlowych dotyczących współpracy handlowej, a także produktów i usług oferowanych przez tę Spółkę'} />
                                            <CFormText className="text-right" color="danger">
                                                {errors.consents?.consent1?.message}
                                            </CFormText>
                                        </CFormGroup>
                                        {/*<CFormGroup>*/}
                                        {/*    <CustomInput desc={'Ullamco elit aute ullamco consequat officia laboris. Eiusmod aliquip minim aute labore mollit esse sint deserunt sint aute. Et dolor sit voluptate irure laborum exercitation nostrud officia anim mollit. Ullamco dolor aliquip sunt excepteur occaecat quis ea.'} />*/}
                                        {/*</CFormGroup>*/}
                                        {/*<CFormGroup>*/}
                                        {/*    <CustomInput desc={'Elit elit in Lorem excepteur tempor laborum. Laborum nostrud eu ex esse Lorem reprehenderit dolore ea Lorem dolore commodo minim. Do excepteur fugiat consequat esse anim enim. Velit ut fugiat ullamco aute anim excepteur fugiat Lorem enim.'} />*/}
                                        {/*</CFormGroup>*/}

                                        <CButton className='w-100' type="submit" size="xl" color="primary" shape="square">
                                            Zarejestruj
                                        </CButton>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {/* STEP 2 */}
                    {
                        step === 2 && (
                            <div className={styles.wrapper}>
                                <h3 className={styles.h3}>Potwierdź adres email</h3>
                                <CheckCircle className={styles.CheckCircleIcon} />
                                <p>Na podany przez Ciebie adres wysłaliśmy wiadomość mailową z linkiem do aktywacji profilu w naszym serwisie. Sprawdź swoją skrzynkę pocztową!</p>
                            </div>
                        )
                    }
                </CForm>

            </div>
            <TheFooter />
        </>
    )
}

export default Register
