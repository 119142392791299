import { CInput } from "@coreui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import DataCard from "../../../components/DataCard/DataCard";
import DataRow from "../../../components/DataRow/DataRow";
import LoaderService from "../../../services/LoaderService";
import SettingsService from "../../../services/SettingsService";
import classes from "../Settings.module.css";
import styles from "../Matrix.module.css";

const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+(,\d{2})?"
        className={styles.numberInput}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};
const PartnerCalcParamsBox = ({adminMode = false}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm();

  const { isFetching, refetch, data } = useQuery(
    "settings-calculator",
    () => SettingsService.getCalculatorDataPartner(),
    {
      onSuccess: (data) => {
        reset(data);
      },
    }
  );
  const [updateCalculatorValue] = useMutation(
    (values) => SettingsService.updateCalculatorDataPartner(values),
    {
      onSuccess: async () => {
        await refetch();
        toast.success("Zaktualizowano dane");
      },
      onError: async () => {
        LoaderService.hide();
        toast.error("Wystąpił błąd podczas zapisywania");
      },
    }
  );
  useEffect(() => {
    if (isFetching) {
      LoaderService.show();
    } else {
      LoaderService.hide();
    }
  }, [isFetching]);
  const onSubmit = (values) => {
    updateCalculatorValue(values.data);
    LoaderService.show();
  };


  if (!data?.data) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DataCard
        title="Rabaty"
        HeaderButtons={
          adminMode && <div>
            <Button type="submit" disabled={!isDirty}>
              Zapisz
            </Button>
          </div>
        }
      >


        <DataRow
          label="Rabat partnerski na produkty główne"
          value={
            adminMode? <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.partner_discount_main"
                innerRef={register}
              />
            </div> : data?.data.partner_discount_main + '%'
          }
        />
        <DataRow
          label="Rabat partnerski na produkty dodatkowe i akcesoria"
          value={
            adminMode? <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.partner_discount_additional"
                innerRef={register}
              />
            </div>: data?.data.partner_discount_additional + '%'
          }
        />
      </DataCard>
    </form>
  );
};

export default PartnerCalcParamsBox;
