import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import { useMutation, useQuery } from 'react-query'
import FeedService from '../../../services/FeedService'
import DataCard from '../../../components/DataCard/DataCard'
import DataRow from '../../../components/DataRow/DataRow'
import { CInput } from '@coreui/react'
import { Controller, useForm } from 'react-hook-form'
import ReactSelect from 'react-select'
import Checkbox from '../../../components/Checkbox/Checkbox'
import { toast } from 'react-toastify'
import Loader from '../../../components/Loader/Loader'
import { useStoreState } from 'easy-peasy'
import ProductService from "../../../services/ProducstService";


// feed options for select
const feedTypeOptions = [
  {
    value: 'GOOGLE',
    label: 'Google Merchant'
  },
  {
    value: 'CENEO',
    label: 'Ceneo'
  },
  {
    value: 'FB',
    label: 'Facebook'
  },
  {
    value: 'SPREADSHEET',
    label: 'Google Spreadsheet'
  }
]

const convertErrors = (errorsArr) => {
  const errors = errorsArr.response.data.errors
  const err = {}
  for (let i = 0; i < errors.length; i++) {
    const invalidproperty = errors[i].invalid_property
    const message = errors[i].message
    if (Array.isArray(err[invalidproperty])) {
      err[invalidproperty] = [...err[invalidproperty], message]
    }
    else {
      err[invalidproperty] = [message]
    }
  }
  return err
}

const Feed = ({ shopId, shop }) => {


  // --------------------------------------------
  // ---------- STATES---------------------------
  // --------------------------------------------
  const [isEditable, setIsEditable] = useState(false)
  const [feedId, setFeedId] = useState(null)
  const [integrationRunning, setIntegrationRunning] = useState(null)

  const { register, handleSubmit, control, reset, setError, formState: { errors } } = useForm()
  const role = useStoreState((state) => state.auth.role);



  // --------------------------------------------
  // ---------- FETCH DATA SHOP FEEDS -----------
  // --------------------------------------------

  const {
    data: dataShopFeeds,
    isLoading: isLoadingShopFeeds,
    isSuccess: isSuccessShopFeeds,
    refetch: refetchShopFeeds
  } = useQuery(
    `shop-feeds-${shopId}`,
    FeedService.getAllShopFeedsByShopId(shopId),
    {
      enabled: !!shopId,
      onSuccess: (data) => {
        if (data.length === 0) {
          const newDataForm = {
            url: '',
            type: '',
            enabled: true,
            partnerCanEdit: false
          }
          reset(newDataForm)
        }
        else {
          const newDataForm = { ...data[0] }
          newDataForm.type = feedTypeOptions.find(item => item.value === newDataForm.type)
          reset(newDataForm)
          setFeedId(newDataForm.id)
        }
      }
    }
  )

  const [runIntegration] = useMutation(
    ProductService.runIntegration(shop),
    {
      onSuccess: () => {
        toast.success("Wykonanie integracji z " + shop);
      },
      onError: () => {
        toast.error("Wystąpił błąd");
      }
    }
  );
  // --------------------------------------------
  // ---------- ADD SHOP FEEDS BY ADMIN ---------
  // --------------------------------------------

  const [addShopFeedsByAdmin] = useMutation(
    FeedService.addShopFeedsByAdmin(),
    {
      onSuccess: () => {
        toast.success('Pomyślnie dodano feed.')
        refetchShopFeeds()
        setIsEditable(false)
      },
      onError: (error) => {
        const err = convertErrors(error)
        for (const k in err) {
          setError(k, { type: 'manual', message: err[k].join(', ') })
        }
        toast.error("Ups... Coś poszło nie tak.")
      }
    }
  )

  // --------------------------------------------
  // ---------- ADD SHOP FEEDS ------------------
  // --------------------------------------------

  const [addShopFeeds] = useMutation(
    FeedService.addShopFeeds(),
    {
      onSuccess: () => {
        toast.success('Pomyślnie dodano feed.')
        refetchShopFeeds()
        setIsEditable(false)
      },
      onError: (error) => {
        const err = convertErrors(error)
        for (const k in err) {
          setError(k, { type: 'manual', message: err[k].join(', ') })
        }
        toast.error("Ups... Coś poszło nie tak.")
      }
    }
  )


  // --------------------------------------------
  // ---------- UPDATE SHOP FEEDS BY ADMIN ------
  // --------------------------------------------

  const [updateShopFeedsByAdmin, { isLoading: isLoadingUpdateShopFeedsByAdmin }] = useMutation(
    FeedService.updateShopFeedsByAdmin(feedId),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowano feed.')
        refetchShopFeeds()
        setIsEditable(false)
      },
      onError: (error) => {
        const err = convertErrors(error)
        for (const k in err) {
          setError(k, { type: 'manual', message: err[k].join(', ') })
        }
        toast.error("Ups... Coś poszło nie tak.")
      }
    }
  )

  // --------------------------------------------
  // ---------- UPDATE SHOP FEEDS ------
  // --------------------------------------------

  const [updateShopFeeds, { isLoading: isLoadingUpdateShopFeeds }] = useMutation(
    FeedService.updateShopFeeds(feedId),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowano feed.')
        refetchShopFeeds()
        setIsEditable(false)
      },
      onError: (error) => {
        const err = convertErrors(error)
        for (const k in err) {
          setError(k, { type: 'manual', message: err[k].join(', ') })
        }
        toast.error("Ups... Coś poszło nie tak.")
      }
    }
  )


  // --------------------------------------------
  // ---------- HANDLERS-------------------------
  // --------------------------------------------

  const handleEdit = () => {
    setIsEditable(true)
  }

  const runIntegrationHandler = () => {
    setIntegrationRunning(true);
    toast.success("Zlecono synchronizacje. To może potrwać kilka minut... ");
    runIntegration().then(() => {
      setIntegrationRunning(false);
    })
  }


  const onSubmit = (values) => {
    const newValues = { ...values }
    newValues.shop = { id: shopId }
    if (values?.type?.value) {
      newValues.type = values.type.value
    }

    if (role === 'ROLE_ADMIN') {
      dataShopFeeds.length === 0 ? addShopFeedsByAdmin(newValues) : updateShopFeedsByAdmin(newValues)
    }
    else {
      delete newValues.partnerCanEdit
      dataShopFeeds.length === 0 ? addShopFeeds(newValues) : updateShopFeeds(newValues)
    }

  }


  // --------------------------------------------
  // ---------- SIDE EFFECT----------------------
  // --------------------------------------------



  // --------------------------------------------
  // ---------- LOCAL STATUS----------------------
  // --------------------------------------------
  const isLoading = isLoadingShopFeeds || isLoadingUpdateShopFeedsByAdmin || isLoadingUpdateShopFeeds
  const isSuccess = isSuccessShopFeeds;


  return (
    <>
      {
        isLoading && <Loader show />
      }
      {
        isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DataCard
              title={"Parametry feed produktowego"}
              HeaderButtons={
                <div className='d-flex gap-3'>
                  {
                    !isEditable && (role === 'ROLE_ADMIN' || role === 'ROLE_ADMIN_PRODUCT_MANAGER' || dataShopFeeds[0]?.partnerCanEdit) && <Button onClick={(handleEdit)}>Edytuj</Button>
                  }
                  {isEditable && <Button type='submit'>Zapisz</Button>}
                  {isEditable && <Button onClick={() => { setIsEditable(false) }}>Anuluj</Button>}
                  {role === 'ROLE_ADMIN' && <Button onClick={runIntegrationHandler} disabled={integrationRunning}>Synchronizuj feed teraz</Button>}
                </div>
              }
            >

              <DataRow
                label={'Link do feed produktowego'}
                InvalidInput={errors?.url?.message}
                value={
                  isEditable ? (
                    <CInput
                      name="url"
                      innerRef={register}
                    />
                  ) : (
                    dataShopFeeds[0]?.url
                  )
                }
              />

              <DataRow
                label={'Typ feed produktowego'}
                InvalidInput={errors?.type?.message}
                value={
                  isEditable ? (
                    <Controller
                      name="type"
                      control={control}
                      render={({ onChange, value }) => (
                        <ReactSelect
                          className='flex-fill'
                          options={feedTypeOptions}
                          value={value}
                          onChange={onChange}
                          styles={{
                            menuList: (provided) => ({
                              ...provided,
                              textAlign: "left",
                            }),
                          }}
                        />
                      )}
                    />
                  ) : (
                    dataShopFeeds[0]?.type
                  )
                }
              />

              <DataRow
                label='Synchronizacja feed produktowego zgodnie z harmonogramem (nie/tak)'
                InvalidInput={errors?.enabled?.message}
                value={
                  <Controller
                    control={control}
                    name="enabled"
                    render={({ onChange, value, ref }) => (
                      <Checkbox
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                        ref={ref}
                        disabled={!isEditable}
                      />
                    )}
                  />
                }
              />

              {
                role === 'ROLE_ADMIN' && (
                  <DataRow
                    label='Edycja po stronie Partnera (nie/tak)'
                    InvalidInput={errors?.partnerCanEdit?.message}
                    value={
                      <Controller
                        control={control}
                        name="partnerCanEdit"
                        render={({ onChange, value, ref }) => (
                          <Checkbox
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                            ref={ref}
                            disabled={!isEditable}
                          />
                        )}
                      />
                    }
                  />
                )
              }
            </DataCard>
          </form>
        )
      }
    </>
  )
}

export default Feed
