import React from "react";
import LogoSvg from "./assets/logo.svg";
import PropTypes from "prop-types";

const Logo = function ({ width, height }) {
  return (
    <>
      <img src={LogoSvg} alt="vibe logo" width={width} height={height} />
    </>
  );
};
Logo.defaultProps = {
  width: 170,
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
