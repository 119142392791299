import axios from "../axios";


const FeedService = {
    getAllShopFeedsByShopId: (shopId) => () => {
        return axios
            .get(`/panel/shop-feeds/shop/${shopId}`)
            .then((res) => res.data);
    },
    addShopFeedsByAdmin: () => (data) => {
        return axios
            .post(`/panel/admin/shop-feeds`, data)
            .then((res) => res.data);
    },
    updateShopFeedsByAdmin: (shopFeedId) => (data) => {
        return axios
            .put(`/panel/admin/shop-feeds/${shopFeedId}`, data)
            .then((res) => res.data);
    },
    addShopFeeds: () => (data) => {
        return axios
            .post(`/panel/shop-feeds`, data)
            .then((res) => res.data);
    },
    updateShopFeeds: (shopFeedId) => (data) => {
        return axios
            .put(`/panel/shop-feeds/${shopFeedId}`, data)
            .then((res) => res.data);
    },
};

export default FeedService;
