import {CCol, CInput, CRow} from "@coreui/react";
import React, {useEffect } from "react";
import {toast} from "react-toastify";
import Button from "../../../components/Button/Button";
import DataCard from "../../../components/DataCard/DataCard";
import DataRow from "../../../components/DataRow/DataRow";
import {useForm} from "react-hook-form";
import {useMutation, useQuery} from "react-query";
import SettingsService from "../../../services/SettingsService";
import Loader from "../../../components/Loader/Loader";
import styles from "../../../views/Matrix/Matrix.module.css";


const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+(,\d{2})?"
        className={styles.numberInput}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};

const InsuranceParams = ({shop}) => {

  const { register, handleSubmit, reset } = useForm();


  const { data, isFetching, refetch } = useQuery(
    `insurance-${shop}`,
    SettingsService.getInsurance(shop)

  );


  const [updateInsurance, { isLoading: isUpdatingInsurance }] = useMutation(
    SettingsService.updateInsurance(shop),
    {
      onSuccess: (e) => {
        toast.success("Koszt ubezpieczenia podstawowego został zaktualizowany");
        refetch();
      },
      onError: () => {
        toast.error("Wystąpił błąd podczas aktualizowania kosztu ubezpieczenia podstawowego");
      },
    }
  );


  const onSubmit = async (updated_data) => {
    await updateInsurance(updated_data);
  };


  useEffect(() => {

      reset({value:data?.value});
// eslint-disable-next-line
  }, [data]);
  return (
    <>
    {(isFetching || isUpdatingInsurance) && <Loader show />}

    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <DataCard title="Podstawowe Ubezpieczenie Produktu"  HeaderButtons={

        <Button type={"submit"}>Zaktualizuj koszt ubezpieczenia podstawowego</Button>

      }>
        <CRow>

            <CCol sm="12" xl="12">
              <DataRow
                fullHeight
                label="Koszt ubezpieczenia podstawowego [%]"
                value={
                  <>
                  <CRow>
                    <CCol sm="12" xl="12">
                      <NumberInput
                        required
                        placeholder="Uzupełnij"
                        name="value" innerRef={register} />
                    </CCol>
                  </CRow>
                    </>
                }
              />
            </CCol>


        </CRow>
      </DataCard>
    </form>
    </>
  );
};

export default InsuranceParams;
