import axios from "../axios";
import FAKE_JSON from "../views/Shop/FAKE_RESPONSE.json";
import {parseObjectToUrlQuery} from "../helpers";
const ShopService = {
  getShops: (params = {}) => async () => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
    return await axios
      .get(`/panel/admin/shops${parsedParams}`)
      .then((res) => res.data.data);
  },
  getData: (id) => async () => {
    return await axios
      .get(`/panel/admin/shop/${id}`)
      .then((res) => res.data)
      .catch(() => FAKE_JSON);
  },
  getDataPartner: () => async () => {
    return await axios
      .get(`/panel/shop`)
      .then((res) => res.data)
      .catch(() => FAKE_JSON);
  },
  update: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/shop/${id}`, data)
      .then((res) => res.data);
  },
  delete: (id) => async () => {
    return await axios
      .delete(`/panel/admin/shop/${id}`)
      .then((res) => res.data);
  },
  updatePartner: () => async (data) => {
    return await axios
      .put(`/panel/shop`, data)
      .then((res) => res.data);
  },
  getPickupPoints: async (id) => {
    return await axios
      .get(`/panel/admin/pickup-points/shop/${id}`)
      .then((res) => res.data.data);
  },
  getPickupPointsPartner: async () => {
    return await axios.get(`/panel/pickup-points`).then((res) => res.data.data);
  },
  getEmployees: async (id) => {
    return await axios
      .get(`/panel/admin/users/shop/${id}`)
      .then((res) => res.data.data);
  },
  getEmployeesPartner: async () => {
    return await axios.get(`/panel/users`).then((res) => res.data.data);
  },
  getPartners: async (id) => {
    return await axios
      .get(`/panel/admin/partner-users/shop/${id}`)
      .then((res) => res.data.data);
  },
  getPartnersPartner: async () => {
    return await axios.get(`/panel/partner-users`).then((res) => res.data.data);
  },
  getIntegrations: async (id) => {
    return await axios
      .get(`/panel/admin/widget-data/shop/${id}`)
      .then((res) => res.data);
  },
  getIntegrationsPartner: async () => {
    return await axios.get(`/panel/widget-data`).then((res) => res.data);
  },
  createPickupPoint: async (data) => {
    return await axios
      .post("/panel/pickup-point", data)
      .then((res) => res.data);
  },
  updatePickupPoint: (id) => async (data) => {
    return await axios
      .put(`/panel/pickup-point/${id}`, data)
      .then((res) => res.data);
  },
  updatePartnerUser: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/user-partner/${id}`, data)
      .then((res) => res.data);
  },
  removePickupPoint: (id) => async () => {
    return await axios
      .delete(`/panel/pickup-point/${id}`)
      .then((res) => res.data);
  },
  updateNote: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/shop-note/${id}`, data)
      .then((res) => res.data);
  },
  // -----------------------------------------
  // ------------------MANUALS ---------------
  // -----------------------------------------
  getManuals: (shopId) => () => {
    return axios.get(`/panel/admin/manuals/shop/${shopId}`).then((res) => res.data);
  },
  getManualsPartner: () => () => {
    return axios.get(`/panel/manuals`).then((res) => res.data);
  },
  addManual: () => (data) => {
    return axios.post(`/panel/admin/manuals`, data).then(res => res.data)
  },
  getSingleManual: (manualId) => () => {
    return axios.get(`/panel/manuals/${manualId}`).then(res => res.data)
  },
  updateSingleManual: (manualId) => (data) => {
    return axios.put(`/panel/admin/manuals/${manualId}`, data).then(res => res.data)
  },
  deleteSingleManual: (manualId) => () => {
    return axios.delete(`/panel/admin/manuals/${manualId}`).then(res => res.data)
  },

  getAllManualsForAdmin: () => () => {
    return axios.get(`/panel/admin/manuals`).then((res) => res.data);
  },
};

export default ShopService;
