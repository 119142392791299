import axios from "../axios";
import { parseObjectToUrlQuery } from '../helpers'

const CategoryService = {
    createCategory: () => async (data) => {
        return await axios.post('panel/admin/category', data)
            .then(res => res.data)
    },
    getHierarchy: () => async () => {
        return await axios.get('panel/admin/categories/hierarchy')
            .then(res => res.data.data)
    },
    getGoogleCategories: () => async () => {
        return await axios.get('/panel/google-categories')
            .then(res => res.data.data.data)
    },
    getCategoriesForTable: (fixedParams = {}) => (_, params = {}) => {
        const params_all = {...fixedParams,...params};
        const parsedParams = params_all ? `?${parseObjectToUrlQuery(params_all)}` : "";
        return axios.get(`/panel/categories/${parsedParams}`)
            .then(res => res.data)
    },
    getCategories: (params) => () => {
        const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
        return axios.get(`/panel/categories${parsedParams}`)
            .then(res => res.data)
    },
    // getData: async (_, params) => {
    //     const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
    //     return await axios
    //         .get(`/panel/categories${parsedParams}`)
    //         .then((res) => res.data);
    // },
    getSingleCategory: (id) => async () => {
        return axios.get(`panel/admin/category/${id}`)
            .then((res) => res.data.data);
    },
    updateSingleCategory: (id) => async (data) => {
        return axios.put(`panel/admin/category/${id}`, data)
            .then((res) => res.data.data);
    },
    getGoogleCategoriesAll: async (_, params) => {
        const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
        return await axios
            .get(`/panel/admin/google-categories/all${parsedParams}`)
            .then((res) => res.data);
    },
    updateSingleGoogleCategory: (id) => async (data) => {
        return axios.put(`panel/admin/google-category/${id}`, data)
            .then((res) => res.data.data);
    },
    getCeneoCategoriesAll: async (_, params) => {
        const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
        return await axios
            .get(`/panel/admin/ceneo-categories/all${parsedParams}`)
            .then((res) => res.data);
    },
    updateSingleCeneoCategory: (id) => async (data) => {
        return axios.put(`panel/admin/ceneo-category/${id}`, data)
            .then((res) => res.data.data);
    },

    getCeneoCategories: (params) => () => {
        const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
        return axios.get(`/panel/ceneo-categories/${parsedParams}`)
            .then(res => res.data.data)
    },


}

export default CategoryService
