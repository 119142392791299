import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams, Prompt } from "react-router";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Button from "../../components/Button/Button";
import DataCard from "../../components/DataCard/DataCard";
import Loader from "../../components/Loader/Loader";
import Title from "../../components/Title/Title";
import MatrixService from "../../services/MatrixService";
import CorrectionPriceBox from "./components/CorrectionPriceBox";
import CorrectionStateBox from "./components/CorrectionStateBox";
import MatrixForm from "./components/MatrixForm";
import CalcParamsBox from "./components/CalculatorParamsBox";
import ProductImportConfigBox from "./components/ProductImportConfigBox";
import { toast } from "react-toastify";
import ProductService from "../../services/ProducstService";
import scopes from "../../scopes";
import EcommerceParams from "../../views/Shop/components/EcommerceParams";
import InsuranceParams from "../../views/Shop/components/InsuranceParams";
import { useStoreState } from "easy-peasy";
import RecommendedProductsBox from "./components/RecommendedProductsBox";
import FeedImporter from "./components/FeedImporter";

const Matrix = () => {
  const { shop } = useParams();
  const [data, setData] = useState(null);
  const [shopId, setShopId] = useState(null)
  const role = useStoreState((state) => state.auth.role);
  const [touchedFields, setTouchedFields] = useState({});

  const {
    data: brands,
    isFetching: isBrandFetching,
    refetch: refetchBrands,
  } = useQuery(
    `brands`,
    MatrixService.getBrands({
      shop_symbol: shop,
    })
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: data,
    reValidateMode: "onSubmit",
  });
  useEffect(() => {
    reset(data);
    // eslint-disable-next-line
  }, [data]);

  const { isFetching: isMatrixLoading, refetch: refetchMatrix } = useQuery(
    `${shop}-matrix`,
    MatrixService.getData({
      shop_symbol: shop,
      sort_by: "brand_name_desc",
    }),
    {
      enabled: !!brands,
      onSuccess: (categories) => {
        if (categories && Array.isArray(categories) && categories[0]) {
          setShopId(categories[0].shop_id)
        }
        const parsedValues = brands.reduce((a, b) => {
          return {
            ...a,
            ...{
              [b.id]: {
                name: b.brand_name,
                bb1: String(b.bb1),
                bb3: String(b.bb3),
                bb6: String(b.bb6),
                bb12: String(b.bb12),
                bb24: String(b.bb24),
                discountNew: String(b.discountNew),
                discountUsed: String(b.discountUsed),
                bb1_modified: b.bb1_modified,
                bb3_modified: b.bb3_modified,
                bb6_modified: b.bb6_modified,
                bb12_modified: b.bb12_modified,
                bb24_modified: b.bb24_modified,
                discountNew_modified: b.discountNew_modified,
                discountUsed_modified: b.discountUsed_modified,

                rv1: String(b.rv1),
                rv3: String(b.rv3),
                rv6: String(b.rv6),
                rv12: String(b.rv12),
                rv24: String(b.rv24),
                rv1_modified: b.rv1_modified,
                rv3_modified: b.rv3_modified,
                rv6_modified: b.rv6_modified,
                rv12_modified: b.rv12_modified,
                rv24_modified: b.rv24_modified,
                rv_mode: b.rv_mode,
                rv_mode_modified: b.rv_mode_modified,

                brand_id: b.brand_id,
                shop_id: b.shop_id,
                categories: categories
                  .filter((c) => c.brand_id === b.brand_id)

                  .reduce(
                    (_a, _b) => ({
                      ..._a,
                      ...{
                        [_b.id]: {
                          name: _b.category_name,
                          category_id: _b.category_id,

                          bb1: String(_b.bb1),
                          bb3: String(_b.bb3),
                          bb6: String(_b.bb6),
                          bb12: String(_b.bb12),
                          bb24: String(_b.bb24),
                          discountNew: String(_b.discountNew),
                          discountUsed: String(_b.discountUsed),

                          rv1: String(_b.rv1),
                          rv3: String(_b.rv3),
                          rv6: String(_b.rv6),
                          rv12: String(_b.rv12),
                          rv24: String(_b.rv24),
                          brand_id: _b.brand_id,
                          shop_id: _b.shop_id,
                          rv_mode: _b.rv_mode,
                        },
                      },
                    }),
                    {}
                  ),
              },
            },
          };
        }, {});
        setData(parsedValues);
      },
    }
  );
  const [updateMatrix, { isLoading: isUpdateLoading }] = useMutation(
    MatrixService.updateMatrix(),
    {
      onSuccess: async () => {
        await refetchBrands();
        await refetchMatrix();
        toast.success("Zmiany w macierzy zostały zapisane");
      },
      onError: () => {
        toast.error("Wystąpił błąd");
      }
    }
  );

  const [runIntegration, { isLoading: isIntegrationRunning }] = useMutation(
    ProductService.runIntegration(shop),
    {
      onSuccess: () => {
        toast.success("Zlecono wykonanie integracji z " + shop);
      },
      onError: () => {
        toast.error("Wystąpił błąd");
      }
    }
  );


  const getRows = (values) => {


    const touchedFieldsFiltered = {...touchedFields};

    if(dirtyFields){
      for (const dirtyFieldName of Object.getOwnPropertyNames(dirtyFields)) {
        if(touchedFields.hasOwnProperty(dirtyFieldName)){
          delete touchedFieldsFiltered[dirtyFieldName];
        }
      }
    }

    return Object.entries({...dirtyFields,...touchedFieldsFiltered}).reduce(
      (rows, [id, { categories: _categories, ...object }]) => {
        const {
          categories,
          brand_name,
          brand_id,
          category_id,
          shop_id,
          ...rest
        } = values[id];
        const requiredData = {
          ...(brand_id ? { brand_id } : {}),
          ...(category_id ? { category_id } : {}),
          ...(shop_id ? { shop_id } : {}),
        };
        let categoryRows;
        if (_categories) {
          categoryRows = Object.entries(_categories).map(([cId]) => {
            return {
              ...categories[cId],
              id: cId,
            };
          });
        }

        const categoriesValues = categoryRows?.map((c, index) => {
          return Object.keys(_categories[c.id]).reduce(
            (a, key) => {
              return {
                ...a,
                [key]: c[key],
              };
            },
            { ...requiredData, id: c.id }
          );
        });

        const updatedValues = Object.entries(object).reduce(
          (a, [field]) => {
            return { ...a, [field]: rest[field] };
          },
          { ...requiredData }
        );

        return [
          ...rows,
          ...(Object.keys(object).length > 0 ? [updatedValues] : []),
          ...(categoriesValues ? categoriesValues : []),
        ];
      },
      []
    );
  };

  const onSubmit = async (values, e) => {
    await setTouchedFields(prevState => prevState);
    const rows = getRows(values);
    await updateMatrix({ rows });
    await setTouchedFields({});

  };

  const isLoading = !!isMatrixLoading || !!isBrandFetching || !!isUpdateLoading || !!isIntegrationRunning;



  return (
    <>
      <Breadcrumb
        path={[
          { label: "Panel administracyjny", url: "/" },
          { label: "Wybierz sklep", url: "/matrix" },
          { label: shop },
        ]}
      />
      {isLoading && <Loader show />}
      <CContainer fluid>
        <main className="c-main">
          <div className="d-flex align-items-center justify-content-between">
            <Title>Ustawienia produktów</Title>
            <div className="mb-4">
              <Button to="/matrix">Zmień partnera</Button>
            </div>
          </div>
          {
            role !== 'ROLE_ADMIN_PRODUCT_MANAGER' && (
              <CRow>
                <CCol sm="12" xl="12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DataCard
                      title="Macierz wartości odkupów BB (Buy Back). Wszystkie wartości BB rozumiane są jako % cen cennikowych"
                      HeaderButtons={
                        <div>
                          <Button type="submit" disabled={isLoading} id="matrix-submit">
                            Zapisz
                          </Button>
                        </div>
                      }
                    >
                      <CRow>
                        <CCol sm="12" xl="12">
                          <p>
                            Po dokonaniu zmiany wciśnij klawisz “Enter” aby zapisać
                            zmianę lub przycisk “Zapisz”, aby zapisać wszystkie
                            zmiany.
                          </p>
                        </CCol>

                        <CCol sm="12" xl="12" className="mt-3">
                          {!!data && <MatrixForm data={data} register={register} setTouchedFields={setTouchedFields} />}
                        </CCol>
                      </CRow>
                    </DataCard>
                  </form>
                </CCol>
              </CRow>
            )
          }

          {
            role !== 'ROLE_ADMIN_PRODUCT_MANAGER' && (
              <>
                <Title className="mt-2 mb-0">Parametry kalkulacji</Title>
                <p className="mb-0">
                  Korekta wartości odkupu dla przedziałów cenowych. Wartości rozumiane
                  jako dodatkowy % dodawany lub odejmowany od wartości BB także
                  wyrażonej w %
                </p>

                <CRow>
                  <CCol sm="4" xl="4">
                    <CorrectionStateBox />
                  </CCol>
                  <CCol sm="8" xl="8">
                    <CorrectionPriceBox />
                  </CCol>
                </CRow>
                <Title className="mt-2 mb-0">Parametry kalkulatora najmu</Title>
                <p className="mb-4">
                  Wskaźniki marżowe i kosztowe. Wszystkie dane w %
                </p>
                <CRow>
                  <CCol>
                    <CalcParamsBox shop={shop} refetchMatrix={refetchMatrix} refetchBrands={refetchBrands} />
                  </CCol>
                </CRow>
              </>
            )
          }



          {
            shop && (role === scopes.ADMIN || role === scopes.ADMIN_TRADER ||
              role === scopes.ADMIN_ANALYST || role === scopes.ADMIN_MANAGER) && <div className="mt-2">
              <EcommerceParams shop={shop} />
            </div>
          }

          {
            shop && (role === scopes.ADMIN || role === scopes.ADMIN_TRADER ||
              role === scopes.ADMIN_ANALYST || role === scopes.ADMIN_MANAGER) &&
            <div className="mt-2">
              <InsuranceParams shop={shop} />
            </div>
          }
          {(shop === 'KLAW' || shop === "SKMU" || shop === 'REGI' || shop === 'PASJ' || shop === 'AKOR') ?
            <>
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <Title className="mt-2 mb-0">Parametry automatycznego importu Partnera</Title>
                  <p className="mb-4">
                    Parametry kryterium produktów, które są dostępne na liście akceptacyjnej
                  </p>
                </div>
                <Button onClick={runIntegration} className={'mt-3'}>Uruchom integrację z {shop}</Button>
              </div>
              <CRow>
                <CCol>
                  <ProductImportConfigBox shop={shop} />
                </CCol>
              </CRow>
            </>
            :
            <CRow>
              <CCol>
                <FeedImporter shop={shop} shopId={shopId} />
              </CCol>
            </CRow>
          }
          <CRow>
            <CCol>
              <RecommendedProductsBox />
            </CCol>
          </CRow>
        </main>
      </CContainer>

      <Prompt
        when={Object.entries(dirtyFields)?.length > 0}
        message="Masz niezapisane zmiany. Czy na pewno chcesz opuścić stronę?"
      />
    </>
  );
};

export default Matrix;
