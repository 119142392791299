import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import { queryCache } from "../../App";

const Logout = function () {
  const clearUser = useStoreActions((actions) => actions.auth.clearUser);
  useEffect(() => {
    clearUser();
    queryCache.clear();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Logout;
