import React, { useMemo } from 'react'
import Title from '../../../components/Title/Title'
import { CDataTable } from '@coreui/react'
import Loader from '../../../components/Loader/Loader'
import Button from '../../../components/Button/Button'
import SelectCell from './SelectCell'
import styles from './RecommendedProductsBox.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import PartnerCheckBox from './PartnerCheckBox'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../../services/RecommendedProductsService'
import { useStoreActions, useStoreState } from 'easy-peasy'


const fields = [
    {
        key: 'main',
        label: 'Kategoria produktu głównego'
    },
    {
        key: 'recommended',
        label: 'Rekomendowane kategorie akcesoriów i produktów dodatkowych'
    },
    {
        key: 'products',
        label: 'Rekomendowane akcesoria i produkty dodatkowe'
    },
    {
        key: 'services',
        label: 'Rekomendowane usługi'
    },
]



const RecommendedProductsBox = () => {
    const history = useHistory()
    const { shop } = useParams()
    const setCategory = useStoreActions(actions => actions.recommendedProducts.setCategory)
    const role = useStoreState((state) => state.auth.role);

    const parseValues = (values) => {
        return values.map(product => true && { label: product.name, value: product.id, })
    }

    const handleManageProducts = (mainProductId, type, name) => {
        setCategory(name)
        history.push(`/${shop}/recommended-products/${mainProductId}/type/${type}`)
    }


    // ---------------------------------------------------------------
    // --------------- GET ALL MAIN PRODUCT CATEGORIES ---------------
    // ---------------------------------------------------------------
    const {
        data: mainProductCategoriesData,
        isLoading: isLoadingMainProductCategories,
        isSuccess: isSuccessMainProductCategories,
        refetch: refetchMainProductCategories
    } = useQuery(
        ['allMainProductCategories', shop],
        RecommendedProductsService.getAllMainProductCategories(shop)
    )


    // ---------------------------------------------------------------
    // --------------- GET ALL MAIN PRODUCT CATEGORIES ---------------
    // ---------------------------------------------------------------

    const {
        data: allRecommendedCategoriesDropdownData,
        isLoading: isLoadingAllRecommendedCategoriesDropdown,
        isSuccess: isSuccessAllRecommendedCategoriesDropdown
    } = useQuery(
        ['allRecommendedCategoriesDropdown', shop],
        RecommendedProductsService.getAllRecommendedCategoriesDropdown(shop)
    )


    // ---------------------------------------------------------------
    // --------------- GET SWITCH POSITION----------------------------
    // ---------------------------------------------------------------

    const {
        data: switchData,
        isLoading: isLoadingSwitch,
        isSuccess: isSuccessSwitch
    } = useQuery(
        ['recommmendedCategoriesSwitch', shop],
        RecommendedProductsService.getRecommendedCategoriesSwitch(shop)
    )




    const isLoading = isLoadingMainProductCategories || isLoadingAllRecommendedCategoriesDropdown || isLoadingSwitch
    const isSuccess = isSuccessMainProductCategories && isSuccessAllRecommendedCategoriesDropdown && isSuccessSwitch

    const isDisabledTable = useMemo(() => {
        return role !== 'ROLE_ADMIN' && role !== 'ROLE_ADMIN_PRODUCT_MANAGER' && !switchData?.switch
    }, [role, switchData])







    return (
        <>
            {
                isLoading && <Loader />
            }
            {
                isSuccess && (
                    <div>
                        {/* HEADER */}
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <Title className='m-0'>Ustawienia produktów rekomendowanych</Title>
                            <div className='d-flex align-items-center gap-3'>
                                <p className='m-0'>Edycja partnera</p>
                                <PartnerCheckBox value={switchData.switch} isDisabled={isDisabledTable} />
                            </div>
                        </div>
                        {/* TABLE */}
                        <div className={styles.tableWrapper}>
                            <CDataTable
                                fields={fields}
                                items={mainProductCategoriesData}
                                scopedSlots={{
                                    'main': ({ name }) => (
                                        <td>
                                            {name}
                                        </td>
                                    ),
                                    'recommended': ({ id, recommended }) => (
                                        <td>
                                            <SelectCell isDisabled={isDisabledTable} mainProductCategoryId={id} options={parseValues(allRecommendedCategoriesDropdownData)} value={parseValues(recommended)} refetchMainProductCategories={refetchMainProductCategories} />
                                        </td>
                                    ),
                                    'products': ({ id, products, name }) => (
                                        <td>
                                            <div className='d-flex align-items-center gap-2 flex-wrap'>
                                                {`Wybrano produktów: ${products}`}

                                                <Button
                                                    disabled={isDisabledTable}
                                                    onClick={() => handleManageProducts(id, 'product', name)}
                                                >
                                                    Dodaj lub usuń
                                                </Button>

                                            </div>
                                        </td>
                                    ),
                                    'services': ({ id, services, name }) => (
                                        <td>

                                            <div className='d-flex align-items-center gap-2 flex-wrap'>
                                                {`Wybrano usług: ${services}`}

                                                <Button
                                                    disabled={isDisabledTable}
                                                    onClick={() => handleManageProducts(id, 'service', name)}
                                                >
                                                    Dodaj lub usuń
                                                </Button>

                                            </div>
                                        </td>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default RecommendedProductsBox