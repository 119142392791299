import { format as dateFnsFormat } from "date-fns";
import polishLocale from "date-fns/locale/pl";
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const polishPlural = (
  singularNominativ,
  pluralNominativ,
  pluralGenitive,
  value
) => {
  if (value === 1) {
    return singularNominativ;
  } else if (
    value % 10 >= 2 &&
    value % 10 <= 4 &&
    (value % 100 < 10 || value % 100 >= 20)
  ) {
    return pluralNominativ;
  } else {
    return pluralGenitive;
  }
};

export const fileToBase64 = async (file) => {
  const fr = new FileReader();
  return new Promise((resolve, reject) => {
    try {
      fr.onload = function () {
        const base64 = fr.result;
        const parsedBase64 = base64.substr(base64.indexOf(",") + 1);
        resolve(parsedBase64);
      };
      fr.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const parseObjectToUrlQuery = (object) =>
  Object.entries(object)
    .map((a) => a.join("="))
    .join("&");

export const parseCurrency = (val) => {
  if (!val) return 0.0;
  return parseFloat(val.replace(" ", "").replace(",", "."));
};

export const parsePointToComa = (val) => {
  if (!val) return '0';
  return val.toString().replace(".", ',')
}

const formatter = Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "PLN",
  minimumFractionDigits: 2,
  // currencySign: "accounting",
});
export const formatCurrency = (value) => {
  return formatter.format(value);
};

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
};

export const dateFormat = (date, _format) =>
  dateFnsFormat(date, _format, { locale: polishLocale });

export const parseBytes = (size, prefix) => {
  if (size > 999999) {
    return `${Math.round(size / 100000) / 10}${prefix && ' MB'}`
  }
  else if (size > 999) {
    return `${parseInt(size / 1000)}${prefix && ' KB'}`
  }
  else {
    return `${size}${prefix && ' B'}`
  }
}

export const debounce = (func, timeout = 1000) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}


