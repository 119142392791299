import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Checkbox from '../../../components/Checkbox/Checkbox'
import RecommendedProductsService from '../../../services/RecommendedProductsService'

const PartnerCheckBox = ({ value, isDisabled = false }) => {
    const { shop } = useParams()
    const { control } = useForm()

    const [setRecommendedCategoriesSwitch, { isLoading }] = useMutation(
        RecommendedProductsService.setRecommendedCategoriesSwitch(shop),
        {
            onSuccess: () => {
                toast.success("Pomyślnie zmieniono ustawienia partnera.")
            },
            onError: () => {
                toast.error("ups... Coś poszło nie tak");
            }
        }
    )

    const handleOnChange = (e) => {
        setRecommendedCategoriesSwitch({ switch: e.target.checked })
    }
    return (
        <Controller
            control={control}
            name="partnerEdit"
            defaultValue={value}
            render={({ value, onChange, ref }) => (
                <Checkbox
                    onChange={(e) => {
                        onChange(e.target.checked)
                        handleOnChange(e)
                    }}
                    checked={value}
                    ref={ref}
                    disabled={isLoading || isDisabled}

                />
            )}
        />
    )
}

export default PartnerCheckBox