import { CFade } from "@coreui/react";
import { useStoreState } from "easy-peasy";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
// routes config
import routes from "../routes";
import NotFound from "../views/NotFound/NotFound";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  const role = useStoreState((state) => state.auth.user?.role?.name);
  return (
    <Suspense fallback={loading}>
      <Switch>
        {routes
          .filter((route) => !route.scopes || route.scopes.indexOf(role) !== -1)
          .map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )}
                />
              )
            );
          })}
        <Route component={NotFound}></Route>
      </Switch>
    </Suspense>
  );
};

export default React.memo(TheContent);
