import axios from "../axios";
import { parseObjectToUrlQuery } from "../helpers";

const BrandsService = {
  getData: async (_, params) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : "";
    return await axios
      .get(`/panel/admin/brands${parsedParams}`)
      .then((res) => res.data);
  },
  getBrands: () => async () => {
    return await axios.get(`panel/brands`).then(res => res.data.data)
  }
};

export default BrandsService;
