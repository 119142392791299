import Axios from "axios";
import stores from "./stores";

const axios = Axios.create({
  responseType: "json",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    ...{
      "Content-Type": "application/json",
    },
  },
});
axios.interceptors.request.use(function (config) {
  const token = stores.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axios;
