import React, { useState } from 'react'
import RSelect from "react-select";
import { useForm, Controller } from 'react-hook-form'
import Button from '../../../components/Button/Button'
import { useMutation } from 'react-query';
import RecommendedProductsService from '../../../services/RecommendedProductsService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SelectCell = ({ refetchMainProductCategories, mainProductCategoryId, options, value, isDisabled = false }) => {

    const { shop } = useParams()

    const [setRecommendedCategories] = useMutation(
        RecommendedProductsService.setRecommendedCategories(mainProductCategoryId, shop),
        {
            onSuccess: () => {
                toast.success("Pomyślnie ustawiono kategorie.")
                refetchMainProductCategories()
            },
            onError: () => {
                toast.error("Ups... Coś poszło nie tak.")
            }
        }
    )

    const { handleSubmit, control } = useForm()
    const [isShowButton, setIsShowButton] = useState(false)


    const parseSelectValues = (values) => {
        return values.select.map(value => true && { id: value.value })
    }


    const onSubmit = (values) => {
        const parsedValues = parseSelectValues(values)
        setRecommendedCategories({
            recommended: parsedValues
        })
    }

    const handleChange = () => {
        setIsShowButton(true)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name='select'
                control={control}
                defaultValue={value}
                render={({ value, onChange }) => (
                    <RSelect
                        value={value}
                        onChange={(e) => {
                            onChange(e)
                            handleChange()
                        }}
                        options={options}
                        isMulti
                        isDisabled={isDisabled}
                    />
                )}
            />
            {
                isShowButton && <Button className='mt-2' type='submit'>Zapisz</Button>
            }
        </form>
    )
}

export default SelectCell