import axios from "../axios";

const AuthService = {
  CheckToken: async (token) => {
    return await axios
      .get("/panel/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data) {
          return res.data;
        }
        throw Error("Bad response");
      })
      .then((res) => res);
  },
  Login: async ({ username: uuid, password }) => {
    return await axios
      .post("/login_check", {
        uuid,
        password,
      })
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          return res.data;
        }
        throw Error("Bad response");
      })
      .then((res) => res)
      .catch((e) => {
        if (e?.response?.status === 401) {

          if(e?.response?.data?.message === 'INACTIVE'){
            throw Error("Twoje konto nie jest aktywne");
          }

          throw Error("Wprowadzono niepoprawne dane");
        }
        throw e;
      });
  },
  Logout: () => {},
};

export default AuthService;
