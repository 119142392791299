import axios from "../axios";

const SettingsService = {
  getCalculatorData: async (shop_symbol) => {
    return await axios.get(`/panel/admin/calculator/${shop_symbol}`).then((res) => res.data);
  },
  getCalculatorDataPartner: async () => {
    return await axios.get(`/panel/calculator`).then((res) => res.data);
  },
  updateCalculatorData: async (values,shop_symbol) => {

    return await axios
      .put(`/panel/admin/calculator/${shop_symbol}`, {...values,

        bail_factor_np: (values.bail_factor_np),
        bail_factor_lp: (values.bail_factor_lp),
        partner_discount_main: (values.partner_discount_main),
        partner_discount_additional: (values.partner_discount_additional),
        annual_financing_cost: (values.annual_financing_cost),
        month_options: values.month_options.map(mo => ({

            profit_main_np: (mo.profit_main_np),
            profit_main_lp: (mo.profit_main_lp),
            profit_additional_np: (mo.profit_additional_np),
            profit_additional_lp: (mo.profit_additional_lp),
            partner_repurchase_discount: (mo.partner_repurchase_discount),
            partner_additional_discount: (mo.partner_additional_discount),
            rate_percent_repurchase: (mo.rate_percent_repurchase),
            month: (mo.month)

        }))
      })
      .then((res) => res.data);
  },

  updateCalculatorDataPartner: async (values) => {

    return await axios
      .put(`/panel/calculator`, {...values,
        partner_discount_main: (values.partner_discount_main),
        partner_discount_additional: (values.partner_discount_additional),
      })
      .then((res) => res.data);
  },

  getIncomeCalculatorData: async () => {
    return await axios
      .get(`/panel/admin/income-calculator`)
      .then((res) => res.data);
  },
  updateIncomeCalculatorData: async (value) => {
    return await axios
      .put(`/panel/admin/income-calculator`, value)
      .then((res) => res.data);
  },
  getTradeMargin: (id) => async () => {
    return await axios
      .get(`/panel/admin/trade-margin/${id}`)
      .then((res) => res.data);
  },
  updateTradeMargin: (id) => async (value) => {
    return await axios
      .put(`/panel/admin/trade-margin/${id}`, value)
      .then((res) => res.data);
  },
  updateInstallments: async (values) => {
    //console.log('AFC',values?.afc);
    return await axios
      .post(`/panel/admin/recalculate-installments/current-afc`, {
        ...values
      })
      .then((res) => res.data);
  },
  getInsurance: (id) => async () => {
    return await axios
      .get(`/panel/admin/insurance/${id}`)
      .then((res) => res.data);
  },
  updateInsurance: (id) => async (value) => {
    return await axios
      .put(`/panel/admin/insurance/${id}`, value)
      .then((res) => res.data);
  },



};

export default SettingsService;
