import React from "react";
import ReactSelect from 'react-select'
import htmlTags from './htmlTags'

const MultiHtmlTagsCategorySelect = ({ onChange, value, ...props }) => {
    return (
        <ReactSelect
            className='flex-fill'
            options={htmlTags}
            isMulti={true}
            name='tags'
            onChange={(htmlTags) =>
                onChange(htmlTags?.map((option) => option.value))
            }
            value={htmlTags.filter((option) => value?.includes(option.value))}
            styles={{
                menuList: (provided) => ({
                    ...provided,
                    textAlign: "left",
                }),
            }}
            {...props}
        />
    );
};

export default MultiHtmlTagsCategorySelect;
