import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { TheContent, TheHeader, TheSidebar, TheHeaderMobile } from "./index";
import { CContainer } from "@coreui/react";
const TheLayout = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const darkMode = useStoreState((state) => state.app.darkMode);
  const classes = classNames(
    "c-app c-default-layout",
    darkMode && "c-dark-theme"
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={classes}>
      <TheSidebar />
      <div className="c-wrapper">
        {windowSize > 768 ? <TheHeader /> : <TheHeaderMobile /> }
        <div className="c-body">
          <TheContent />
        </div>
        <CContainer fluid className={'pb-3'}>
          <small className={'w-100 text-right d-block'}>© Univibe Sp. z o.o. | <a href={'https://www.vibe.pl'} target={"_blank"}>www.vibe.pl</a></small>
        </CContainer>
      </div>
    </div>
  );
};

export default TheLayout;
