import { CCol, CInput, CRow, CSpinner } from "@coreui/react";
import cs from "classnames";
import React, { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import Button from "../../../components/Button/Button";
import DataCard from "../../../components/DataCard/DataCard";
import styles from "../Matrix.module.css";
import MultiBrandSelect from "../../../components/MultiBrandSelect/MultiBrandSelect";
import MultiGoogleCategorySelect from "../../../components/MultiGoogleCategorySelect/MultiGoogleCategorySelect";
import ProductService from "../../../services/ProducstService";
import LoaderService from "../../../services/LoaderService";
import { toast } from 'react-toastify'
import DataRow from "../../../components/DataRow/DataRow";
import MultiCeneoCategorySelect from "../../../components/MultiCeneoCategorySelect/MultiCeneoCategorySelect";
import MultiHtmlTagsCategorySelect from "../../../components/MultiHtmlTagsCategorySelect/MultiHtmlTagsCategorySelect";
import { useStoreState } from "easy-peasy";



const ProductImportConfigBox = ({ shop }) => {
    const role = useStoreState((state) => state.auth.role);

    const { register, reset, handleSubmit, control } = useForm();
    // eslint-disable-next-line
    const { data: dataProductAcceptanceList, isFetching: isFeatchingProductAcceptanceList, isSuccess: isSuccesProductAcceptanceList, isLoading: isLoadingProductAcceptanceList, refetch } = useQuery(
        `product-acceptance-list-${shop}`,
        ProductService.getProductImportConfig(shop),
        {
            onSuccess: (data) => {
                const parsedData = {
                    min: data.min,
                    max: data.max,
                    brandIds: [...new Set(data.brandIds)],
                    googleIds: [...new Set(data.googleIds)],
                    ceneoIds: [...new Set(data.ceneoIds)],
                    tags: [...new Set(data.tags)],
                }
                reset(parsedData)
            }
        }
    );

    const [update, { isLoading: isLoadingUpdate }] = useMutation(
        ProductService.setProductImportConfig(shop),
        {
            onSuccess: async () => {
                await refetch();
                toast.success("Zaktualizowano dane");
            },
            onError: (e) => {
                if (e?.response?.data?.errors?.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    e.response.data.errors.map(({ message }) => {
                        toast.error(message);
                    });
                }
            },
        }
    );

    const onSubmit = async (values) => {
        update(values)
    };

    useEffect(() => {
        if (isFeatchingProductAcceptanceList || isLoadingUpdate) {
            LoaderService.show();
        } else {
            LoaderService.hide();
        }
    }, [isFeatchingProductAcceptanceList, isLoadingUpdate]);

    const isDisabled = useMemo(() => {

        return role !== "ROLE_ADMIN" && role !== 'ROLE_ADMIN_PRODUCT_MANAGER'
    }, [role])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <DataCard
                title={"Parametry importu produktów"}
                HeaderButtons={
                    <div>
                        <Button disabled={isDisabled} type="submit">Zapisz</Button>
                    </div>
                }
            >
                <CRow>
                    <CCol sm="12" xl="12">
                        {isLoadingProductAcceptanceList && (
                            <div
                                className={cs(
                                    "d-flex justify-content-center align-items-center l-0 t-0 w-100 h-100 position-absolute",
                                    styles.loaderContainer
                                )}
                            >
                                <CSpinner
                                    color="primary"
                                    style={{ width: "4rem", height: "4rem" }}
                                />
                            </div>
                        )}
                        {
                            isSuccesProductAcceptanceList && (
                                <>
                                    <DataRow
                                        label='Cena minimalna'
                                        value={
                                            <CInput
                                                name={`min`}
                                                innerRef={register}
                                                type="number"
                                                disabled={isDisabled}
                                            />
                                        }
                                    />
                                    <DataRow
                                        label='Cena maksymalna'
                                        value={
                                            <CInput
                                                name={`max`}
                                                innerRef={register}
                                                type="number"
                                                disabled={isDisabled}
                                            />
                                        }
                                    />
                                    <DataRow
                                        label='Akceptowane kategorie Google ID'
                                        value={
                                            <Controller
                                                name="googleIds"
                                                control={control}

                                                render={({ onChange, value }) => (
                                                    <MultiGoogleCategorySelect
                                                        value={value}
                                                        onChange={onChange}
                                                        isDisabled={isDisabled}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                    <DataRow
                                        label='Akceptowane marki'
                                        value={
                                            <Controller
                                                name="brandIds"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <MultiBrandSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        isDisabled={isDisabled}
                                                    />
                                                )}
                                            />
                                        }
                                    />

                                    <DataRow
                                        label='Akceptowane kategorie Ceneo'
                                        value={
                                            <Controller
                                                name="ceneoIds"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <MultiCeneoCategorySelect
                                                        value={value}
                                                        onChange={onChange}
                                                        isDisabled={isDisabled}
                                                    />

                                                )}
                                            />
                                        }
                                    />
                                    <DataRow
                                        label='Akceptowane tagi HTML'
                                        value={
                                            <Controller
                                                name="tags"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <MultiHtmlTagsCategorySelect
                                                        value={value}
                                                        onChange={onChange}
                                                        isDisabled={isDisabled}
                                                    />
                                                )}
                                            />
                                        }
                                    />

                                </>
                            )
                        }
                    </CCol>
                </CRow>
            </DataCard>
        </form>
    );
};

export default ProductImportConfigBox;
