import React, { useState } from 'react'
import styles from './CustomInput.module.scss'
import { Check } from "react-feather";
import cs from 'classnames'

const CustomInput = ({ desc, className, innerRef, name }) => {
    const [active, setActive] = useState(false)
    const handleClick = () => {
        setActive(active => !active)
    }
    return (
        <label className={cs(styles.label, className)}>
            <div onClick={handleClick} className={styles.customInput}>
                {
                    active && <Check className={styles.icon} />
                }
            </div>
            {
                desc && <div className={styles.desc} onClick={handleClick}>{desc}</div>
            }
            <input ref={innerRef} name={name} className={styles.input} type='checkbox' />
        </label>
    )
}

export default CustomInput
