import { CSidebar, CSidebarClose } from "@coreui/react";
import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";

const TheAside = () => {
  const show = useStoreState((state) => state.app.asideShow);
  const setAsideShow = useStoreActions((state) => state.app.setAsideShow);
  const setState = (state) => setAsideShow(state);

  return (
    <CSidebar
      aside
      colorScheme="light"
      size="lg"
      overlaid
      show={show}
      onShowChange={(state) => setState(state)}
    >
      <CSidebarClose onClick={() => setState(false)} />
      {/*aside content*/}
    </CSidebar>
  );
};

export default React.memo(TheAside);
