import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useStoreState } from "easy-peasy";
// import { useStoreState } from "easy-peasy";
import React from "react";

const TheHeaderDropdown = () => {
  // const email = useStoreState((state) => state.auth.user?.email);
  const role = useStoreState((state) => state.auth.user?.role.name_pl);
  const shop_symbol = useStoreState((state) => state.auth.user?.shop_symbol);
  const names = useStoreState((state) => `${state.auth.user?.name || ''} ${state.auth.user?.surname || ''}`);

  return (
    <>
      <CDropdown inNav className="c-header-nav-items" direction="down">
        <CDropdownToggle className="c-header-nav-link d-flex py-2 px-0" caret={false}>
          {
            (role === 'Administrator') ?
              <div style={{ marginRight: '10px' }}>Admin</div> :
              <div style={{ marginRight: '10px' }}>{names} | {role} {shop_symbol ? `| ${shop_symbol}` : ``}</div>
          }

          <div className="c-avatar bg-primary text-white" style={{minWidth: '36px'}}>
            <CIcon name="cil-user" />

            {/* <CImg
              // src={require("./assets/avatars/6.jpg")}
              className="c-avatar-img"
              alt={email}
            /> */}
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem header tag="div" color="light" className="text-center">

            {
              role === 'Administrator' ?
                <div style={{ marginRight: '10px' }}><strong>Admin</strong></div> :
                <div style={{ marginRight: '10px' }}><strong>{names} | {role} {shop_symbol ? `| ${shop_symbol}` : ``}</strong></div>
            }

          </CDropdownItem>
          {/*         <CDropdownItem>
            <CIcon name="cil-user" className="mfe-2" />
            Ustawienia
          </CDropdownItem> */}
          <CDropdownItem divider />
          <CDropdownItem to="/logout">
            <CIcon name="cil-lock-locked" className="mfe-2" />
            Wyloguj
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default TheHeaderDropdown;
