import {CHeader, CHeaderNav, CToggler} from "@coreui/react";
import {useStoreActions, useStoreState} from "easy-peasy";
import React from "react";
import SearchAgreements from "../components/Searches/SearchAgreements";
import TheHeaderDropdown from "./TheHeaderDropdown";
import styles from './hamburger.module.scss'
import classNames from "classnames";
import SearchProducts from "../components/Searches/SearchProducts";
import SearchTransactions from "../components/Searches/SearchTransactions";
import {PERMISSIONS} from "../scopes";

const TheHeader = () => {
  const sidebarShow = useStoreState((state) => state.app.sidebarShow);
  const setSidebarShow = useStoreActions((state) => state.app.setSidebarShow);
  const role = useStoreState((state) => state.auth.role);

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    setSidebarShow(val);
  };

  return (
    <div className="header-components">
      <CHeader withSubheader className='d-flex flex-column-reverse align-items-start flex-sm-row align-items-sm-center'>
        <CToggler
          inHeader
          // className="d-lg-none p-2 "
          className={classNames(`d-lg-none p-2 ${styles.hamburger}`)}
          onClick={toggleSidebarMobile}
        />
        <CHeaderNav className="ml-auto px-2 ">
          <CHeaderNav className="d-flex gap-2">
            <SearchProducts/>
            {
              PERMISSIONS.ADMIN_TRADER.indexOf(role) !== -1 && <SearchAgreements/>
            }
            {
              PERMISSIONS.TRADER.indexOf(role) !== -1 && <SearchTransactions/>
            }
            <TheHeaderDropdown/>
          </CHeaderNav>
        </CHeaderNav>
      </CHeader>

    </div>
  );
};

export default TheHeader;
