import { CInput } from '@coreui/react'
import React, { useEffect, useRef } from 'react'
import { debounce } from '../../helpers'
import { useHistory } from 'react-router-dom'
import styles from './Search.module.scss'
import { MdClear } from 'react-icons/md'


const SearchProducts = ({className}) => {
    const history = useHistory()

    const inputRef = useRef(null)

    const myDebounce = debounce((e) => {
        if (!!e.target.value) {
            history.push(`/search-products/${e.target.value}`)
        }
        else {
            history.push(`/products/main`)
        }
    })

    const handleOnChange = (e) => {
        e.persist()
        myDebounce(e)
    }

    const handleClear = () => {
        inputRef.current.value = ''
        history.push(`/products/main`)
    }


    useEffect(() => {
        const pathname = history.location.pathname
        const query = pathname.match('/search-products/(.*)')
        if (query) {
            inputRef.current.value = query[1]
        }
        else {
            inputRef.current.value = ''
        }
    }, [history.location.pathname])

    return (
        <div className={styles.wrapper + ` ${className}` }>
            <CInput
                innerRef={inputRef}
                className={styles.search}
                name='searchPorducts'
                onChange={handleOnChange}
                placeholder='Szukaj w produktach'
            />
            <div className={styles.iconWrapper} onClick={handleClear}>
                <MdClear className={styles.icon} />
            </div>
        </div>
    )
}

export default SearchProducts
