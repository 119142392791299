import { useStoreState } from "easy-peasy";
import React, { useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { PERMISSIONS } from "../../scopes";
import ShopService from "../../services/ShopService";
import Select from "../Select";

const ShopSelectImport = ({ onChange, value: shopValue, all_option = false }) => {
    const role = useStoreState((state) => state.auth.role);
    const [data, setData] = useState(null);

    useQuery(`shops`, ShopService.getShops(), {
        onSuccess: (data) => {
            if (all_option && data) {
                setData([{ "entity_id": -1, name: "Wszystkie sklepy" }, ...data]);
            } else {
                setData(data);
            }
        },
        enabled: PERMISSIONS.PARTNER.indexOf(role) === -1,
    });

    useQuery(`shop`, ShopService.getDataPartner(), {
        onSuccess: (data) => {
            setData([data]);
            onChange({
                id: data.id,
                name: data.name,
                symbol: data.symbol,
            });
        },
        enabled: PERMISSIONS.PARTNER.indexOf(role) !== -1,
    });

    const parsedValues = useMemo(() => {
        if (!data) return [];
        return data.map(({ entity_id: id, name, symbol }) => ({
            value: id,
            label: `${name} | ${symbol}`,
            symbol,
        }));
    }, [data]);
    const parsedValue = useMemo(() => {
        if (!shopValue) {
            return null;
        }
        return {
            value: shopValue.id,
            label: shopValue.name,
        };
    }, [shopValue]);

    return (
        <div className="flex-fill">
            <Select
                isLoading={data === null}
                className="flex-fill"
                options={parsedValues}
                value={parsedValue}
                onChange={({ value, label, symbol }) =>
                    onChange({ id: value, name: label, symbol })
                }
            />
        </div>
    );
};

export default ShopSelectImport;
