import { CFormText } from "@coreui/react";
import cs from "classnames";
import React from "react";

const DataRow = ({
  label,
  value,
  fullHeight = false,
  containerClass,
  labelClass,
  InvalidInput = false,
  extraInfo
}) => (
  <div>
    <div
      className={cs("px-1 py-3 border-bottom", containerClass, {
        "h-100 align-items-center": fullHeight,
      })}
    >
      <div className={cs("d-flex")}>
        <div className={cs("flex-fill text-black", labelClass)}>
          <strong>{label}</strong>
        </div>
        <div className="flex-fill text-right d-flex justify-content-end pl-2 align-items-center">
          {/* {typeof value !== "undefined" && value !== null ? value : "-"} */}
          {value ? value : '-'}
        </div>
      </div>
      {InvalidInput && (
        <CFormText className="mt-3 text-right" color="danger">
          {InvalidInput}
        </CFormText>
      )}
      {
        extraInfo && <div>{extraInfo}</div>
      }
    </div>
  </div>
);

export default DataRow;
