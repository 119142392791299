import React from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import ReactSelect from 'react-select'
import BrandsService from "../../services/BrandsService";

const MultiBrandSelect = ({ onChange, value, ...props }) => {

    const { data, isFetching } = useQuery(`brand`, BrandsService.getBrands());

    const parsedValues = useMemo(() => {
        if (!data) return [];
        return data.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }, [data]);
    
    const selectAllOption = { value: "all", label: "Zaznacz wszystko" };
    
    const handleSelectAll = () => {
        const allValues = parsedValues.map((option) => option.value);
        onChange(allValues);
    };
    
    const handleSelectChange = (selectedOptions) => {
        const values = selectedOptions?.map((option) => option.value);
        onChange(values);
    };
    
    return (
        <ReactSelect
            className='flex-fill'
            options={[selectAllOption, ...parsedValues]}
            isMulti={true}
            name='brandIds'
            isLoading={isFetching}
            onChange={(selectedOptions) =>
                selectedOptions.includes(selectAllOption)
                    ? handleSelectAll()
                    : handleSelectChange(selectedOptions)
            }
            value={value === parsedValues.map((option) => option.value) ? [selectAllOption] : parsedValues.filter((option) => value?.includes(option.value))}
            styles={{
                menuList: (provided) => ({
                    ...provided,
                    textAlign: "left",
                }),
            }}
            {...props}
        />
    )};

export default MultiBrandSelect;
