import { CSpinner } from "@coreui/react";
import { useStoreState } from "easy-peasy";
import React from "react";
import styles from "./Loader.module.css";
const Loader = function ({ show }) {
  const loaderShow = useStoreState((state) => state.app.loaderShow);
  if (!loaderShow && !show) return null;
  return (
    <div
      className={`${styles.loaderContainer} d-flex justify-content-center align-items-center position-fixed flex-fill vh-100 vw-100`}
    >
      <CSpinner color="primary" style={{ width: "4rem", height: "4rem", borderWidth: '4px' }} />
    </div>
  );
};

export default Loader;
