import React from "react";
import SCOPES, { PERMISSIONS } from "./scopes";
import Matrix from "./views/Matrix/Matrix";
import PartnerMatrix from "./views/Matrix/PartnerMatrix";
import SelectShop from "./views/Matrix/SelectShop";
// import Search from "./views/Search/Search";

const Redirector = React.lazy(() => import("./views/Redirector/Redirector"));
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const Application = React.lazy(() => import("./views/Application/Application"));
const ApplicationCompany = React.lazy(() =>
  import("./views/Application/ApplicationCompany")
);
const ApplicationEntrepreneur = React.lazy(() =>
  import("./views/Application/ApplicationEntrepreneur")
);
const ApplicationsActive = React.lazy(() =>
  import("./views/Applications/ApplicationsActive/ApplicationsActive")
);
const ApplicationsExamined = React.lazy(() =>
  import("./views/Applications/ApplicationsExamined/ApplicationsExamined")
);
const ApplicationsWrong = React.lazy(() =>
  import("./views/Applications/ApplicationsWrong/ApplicationsWrong")
);
const ApplicationsArchived = React.lazy(() =>
  import("./views/Applications/ApplicationsArchived/ApplicationsArchived")
);
const MainProducts = React.lazy(() =>
  import("./views/Products/Main/MainProducts")
);
const AddMainProduct = React.lazy(() =>
  import("./views/Products/Main/AddMainProduct")
);
const AddAdditionalProduct = React.lazy(() =>
  import("./views/Products/Additional/AddAdditionalProduct")
);
const AddAccessories = React.lazy(() =>
  import("./views/Products/Accessories/AddAccessories")
);
const AddServices = React.lazy(() =>
  import("./views/Products/Services/AddServices")
);
const AdditionalProducts = React.lazy(() =>
  import("./views/Products/Additional/AdditionalProducts")
);
const AddBrand = React.lazy(() =>
  import("./views/Brand/AddBrand")
);
const UpdateBrand = React.lazy(() =>
  import("./views/Brand/UpdateBrand")
);
const Accessories = React.lazy(() =>
  import("./views/Products/Accessories/Accessories")
);
const Services = React.lazy(() => import("./views/Products/Services/Services"));
const Reservations = React.lazy(() =>
  import("./views/Reservations/Reservations")
);
const Agreement = React.lazy(() => import("./views/Agreement/Agreement"));
const Agreements = React.lazy(() => import("./views/Agreements/Agreements"));

const Shops = React.lazy(() => import("./views/Shops/Shops"));
const Shop = React.lazy(() => import("./views/Shop/Shop"));
const NewPickupPoint = React.lazy(() => import("./views/Shop/NewPickupPoint"));
const NewPickupPointAdmin = React.lazy(() => import("./views/Shop/NewPickupPointAdmin"));

const Assets = React.lazy(() => import("./views/Assets/Assets"));
const Asset = React.lazy(() => import("./views/Asset/Asset"));

const ServicesInAssetsAndServices = React.lazy(() => import("./views/Services/Services"));
// const Service = React.lazy(() => import("./views/Service/Service"));


const Product = React.lazy(() => import("./views/Product/Product"));
const Orders = React.lazy(() => import("./views/Orders/Orders"));
const OrdersArchived = React.lazy(() => import("./views/Orders/OrdersArchived"));
const Order = React.lazy(() => import("./views/Order/Order"));

const Customers = React.lazy(() => import("./views/Customers/Customers"));
const LegalCustomer = React.lazy(() =>
  import("./views/Customer/LegalCustomer")
);
const NaturalCustomer = React.lazy(() =>
  import("./views/Customer/NaturalCustomer")
);
const Users = React.lazy(() => import("./views/Users/Users"));
const NewUser = React.lazy(() => import("./views/Users/NewUser"));

const Repurchase = React.lazy(() => import("./views/Repurchase/Repurchase"));
const RepurchaseSingle = React.lazy(() =>
  import("./views/RepurchaseSingle/RepurchaseSingle")
);
const ReturnsSingle = React.lazy(() =>
  import("./views/RepurchaseSingle/ReturnsSingle")
);
const Settings = React.lazy(() => import("./views/Settings/Settings"));
const Ecommerce = React.lazy(() => import("./views/Ecommerce/Ecommerce"));
const Payments = React.lazy(() => import("./views/Payments/Payments"));
const Categories = React.lazy(() => import("./views/Categories/Categories"));
const AddCategory = React.lazy(() => import("./views/Categories/AddCategory"));
const EditCategory = React.lazy(() => import("./views/Categories/EditCategory"));

const AddReturnProtocol = React.lazy(() => import("./views/ReturnProtocol/AddReturnProtocol"));
const UpdateReturnProtocol = React.lazy(() => import("./views/ReturnProtocol/UpdateReturnProtocol"));

const AcceptanceList = React.lazy(() =>
  import("./views/Products/AcceptanceList/AcceptanceList")
);

const Brands = React.lazy(() =>
  import("./views/Brands/Brands")
);

const Search = React.lazy(() =>
  import("./views/Search/Search")
);

const RecommendedProducts = React.lazy(() =>
  import("./views/RecommendedProducts/RecommendedProducts")
);

const AddManual = React.lazy(() =>
  import("./views/AddManual/AddManual")
);

const Manual = React.lazy(() =>
  import("./views/Manual/Manual")
);

const SearchProducts = React.lazy(() =>
  import("./views/SearchProducts/SearchProducts")
);

const SearchTransactions = React.lazy(() =>
  import("./views/SearchTransactions/SearchTransactions")
);

const Manuals = React.lazy(() =>
  import("./views/Manuals/Manuals")
);


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Panel Administracyjny" },

  // {
  //   path: "/applications/:type/:customerType/:id",
  //   exact: true,
  //   name: "Wniosek",
  //   component: Application,
  //   scopes: [SCOPES.ADMIN],
  // },
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/redirector",
    exact: true,
    name: "Redirector",
    component: Redirector,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/applications/:type/personal/:id",
    exact: true,
    name: "Wniosek",
    component: Application,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/:type/company/:id",
    exact: true,
    name: "Wniosek",
    component: ApplicationCompany,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/:type/entrepreneur/:id",
    exact: true,
    name: "Wniosek",
    component: ApplicationEntrepreneur,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/active",
    exact: true,
    name: "Wnioski aktywne",
    component: ApplicationsActive,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/examined",
    exact: true,
    name: "Wnioski rozpatrzone",
    component: ApplicationsExamined,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/error",
    exact: true,
    name: "Wnioski z błędem BIK",
    component: ApplicationsWrong,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/applications/archived",
    exact: true,
    name: "Wnioski zarchiwizowane",
    component: ApplicationsArchived,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/products/main/add",
    exact: true,
    name: "Dodaj Produkt główny",
    component: AddMainProduct,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/additional/add",
    exact: true,
    name: "Dodaj Produkt dodatkowy",
    component: AddAdditionalProduct,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/accessories/add",
    exact: true,
    name: "Dodaj Akcesoria",
    component: AddAccessories,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/services/add",
    exact: true,
    name: "Dodaj usługę",
    component: AddServices,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/products/:type/:id",
    exact: true,
    name: "Produkt",
    component: Product,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/main",
    exact: true,
    name: "Produkty główne",
    component: MainProducts,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/additional",
    exact: true,
    name: "Produkty Dodatkowe",
    component: AdditionalProducts,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/accessories",
    exact: true,
    name: "Akcesoria",
    component: Accessories,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/products/services",
    exact: true,
    name: "Usługi",
    component: Services,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/product-acceptance-list/:symbol",
    exact: true,
    name: "Import listy",
    component: AcceptanceList,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/orders/archived",
    name: "Zamówienia Archiwizowane",
    component: OrdersArchived,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/orders/:id",
    name: "Zamówienie",
    component: Order,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/orders",
    name: "Zamówienia",
    component: Orders,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },

  {
    path: "/customer/legal/:id",
    name: "Klienci",
    component: LegalCustomer,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/customer/natural/:id",
    name: "Klienci",
    component: NaturalCustomer,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/customers",
    name: "Klienci",
    component: Customers,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/reservations",
    name: "Rezerwacje",
    component: Reservations,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/repurchase/:id",
    name: "Odkupy",
    component: RepurchaseSingle,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/repurchase",
    name: "Odkupy",
    component: Repurchase,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/returns/:id",
    name: "Odkupy",
    component: ReturnsSingle,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/assets&services/assets/:id",
    name: "Aktywa",
    component: Asset,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/assets&services/assets",
    name: "Aktywa",
    component: Assets,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/assets&services/services",
    name: "Aktywa",
    component: ServicesInAssetsAndServices,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/agreements/:id",
    name: "Umowa",
    component: Agreement,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/agreements",
    name: "Umowy",
    component: Agreements,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },

  {
    path: "/shops/:id",
    exact: true,
    name: "Partnerzy",
    component: Shop,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/manuals/new",
    exact: true,
    name: "Dodaj Manual",
    component: AddManual,
    scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/manuals/:manualId",
    exact: true,
    name: "Nowy Manual",
    component: Manual,
    scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/shops/:id/manual/:manualId",
    exact: true,
    name: "Nowy Manual",
    component: Manual,
    scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },

  {
    path: "/partners",
    name: "Partnerzy",
    component: Shops,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/manuals",
    name: "Manuals",
    component: Manuals,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/partner-data/add-pickup-point",
    name: "Dodawanie Punktu odbioru",
    component: NewPickupPoint,
    scopes: [...PERMISSIONS.TRADER],
  },
  {
    path: "/partner-data/add-pickup-point/:shop_id",
    name: "Dodawanie Punktu odbioru przez Admina",
    component: NewPickupPointAdmin,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/partner-data",
    name: "Dane Partnera",
    component: Shop,
    scopes: [...PERMISSIONS.TRADERS],
  },
  {
    path: "/users/new",
    name: "Dodawanie pracownika",
    component: NewUser,
    scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_MANAGER],
  },
  {
    path: "/users",
    name: "Pracownicy",
    component: Users,
    scopes: [...PERMISSIONS.MANAGER, ...PERMISSIONS.ADMIN_MANAGER],
  },

  {
    path: "/settings",
    name: "Ustawienia",
    component: Settings,
    scopes: [...PERMISSIONS.ADMIN_MANAGER],
  },
  {
    path: "/ecommerce",
    name: "Ecommerce",
    component: Ecommerce,
    scopes: [...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/payments",
    name: "Płatności",
    component: Payments,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/:shop/partnerMatrix/",
    name: "Ustawienia produktów",
    component: PartnerMatrix,
    // scopes: [...PERMISSIONS.ADMIN_MANAGER],
  },
  {
    path: "/product-acceptance-list",
    name: "Wybierz Partnera",
    component: SelectShop,
    scopes: [...PERMISSIONS.TRADERS, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/:shop/matrix/",
    name: "Ustawienia produktów",
    component: Matrix,
    scopes: [...PERMISSIONS.ADMIN_MANAGER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/:shop/recommended-products/:mainProductId/type/:type",
    name: "Ustawienia rekomendowanych produktó☺w",
    component: RecommendedProducts,
    // scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/matrix/",
    name: "Ustawienia produktów",
    component: SelectShop,
    // scopes: [...PERMISSIONS.ADMIN_MANAGER],
  },
  {
    path: "/categories/",
    name: "Kategorie",
    exact: true,
    component: Categories,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/categories/add",
    name: "Dodaj kategorie",
    exact: true,
    component: AddCategory,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/category/:id",
    name: "Edytuj kategorie",
    exact: true,
    component: EditCategory,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/brands/",
    name: "Marki",
    component: Brands,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/brand/add",
    exact: true,
    name: "Dodaj Markę",
    component: AddBrand,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/brand/:id",
    exact: true,
    name: "Edytuj Markę",
    component: UpdateBrand,
    scopes: [...PERMISSIONS.ADMIN_TRADER, ...PERMISSIONS.ADMIN_PRODUCT_MANAGER],
  },
  {
    path: "/return-protocol/add/:order_id",
    exact: true,
    name: "Dodaj Protokół Zwrotu",
    component: AddReturnProtocol,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/return-protocol/update/:order_id/:id",
    exact: true,
    name: "Edytuj Protokół Zwrotu",
    component: UpdateReturnProtocol,
    scopes: [...PERMISSIONS.TRADER, ...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/search/:query",
    exact: true,
    name: "Szukaj",
    component: Search,
    scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: "/search-products/:query",
    exact: true,
    name: "Szukaj",
    component: SearchProducts,
    scopes: [
      SCOPES.ADMIN_MANAGER,
      SCOPES.ADMIN_TRADER,
      SCOPES.ADMIN_ANALYST,
      SCOPES.ADMIN_PRODUCT_MANAGER,
      SCOPES.ADMIN,
      SCOPES.PARTNER,
      SCOPES.MANAGER,
      SCOPES.TRADER,
    ]
  },
  {
    path: "/search-transactions/:query",
    exact: true,
    name: "Szukaj",
    component: SearchTransactions,
    scopes: [...PERMISSIONS.TRADER]
  },

];

export default routes;
